<template>
  <div class="wap-auth">
    <headerBar :title="$t('shi-ming-ren-zheng')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="label">{{ $t('guo-ji') }}</div>
        <div class="input">
          <Field
            v-model="form.country"
            :placeholder="$t('qing-shu-ru-guo-ji-1')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zhen-shi-xing-ming') }}</div>
        <div class="input">
          <Field
            v-model="form.realName"
            :placeholder="$t('qing-shu-ru-zhen-shi-xing-ming-4')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zheng-jian-hu-zhao-hao-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.cardNo"
            :placeholder="$t('qing-shu-ru-zheng-jian-hu-zhao-hao-ma-4')"
          ></Field>
        </div>
      </div>

      <div class="form-item">
        <div class="flex-center-around">
          <div class="upload flex-column-center">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadUrl"
              name="file"
              :headers="headers"
              :on-success="(file) => uploadSuccess(file, 'cardFront')"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.cardFront"
                :src="getBaseUrl(form.cardFront)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>

            <div>{{ $t('zheng-jian-zheng-mian') }}</div>
          </div>
          <div class="upload flex-column-center">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadUrl"
              name="file"
              :headers="headers"
              :on-success="(file) => uploadSuccess(file, 'cardBack')"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.cardBack"
                :src="getBaseUrl(form.cardBack)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
            <div>{{ $t('zheng-jian-fan-mian') }}</div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="label">{{ $t('pai-zhao-shi-li') }}</div>
        <div class="flex-center-around">
          <div class="demo">
            <img :src="demoUrl1" alt="" />
          </div>
          <div class="demo">
            <img :src="demoUrl2" alt="" />
          </div>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn" @click="submitAuth">{{
          $t('ren-zheng')
        }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import moment from 'moment'
import { shopAuth, getShopAuthStatus } from '@/api/shop'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      avatar: '',
      form: {},
      headers: {},
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png')
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },

    uploadUrl() {
      return this.$store.state.uploadUrl
    },
    imgType() {
      return this.$store.state.imgType
    }
  },
  mounted() {
    this.headers = {
      Token: getToken()
    }
    this.init()
  },
  methods: {
    getBaseUrl,
    back() {
      this.$router.go(-1)
    },
    init() {
      getShopAuthStatus().then((res) => {
        if (res.data) {
          this.$toast(
            this.$t('nin-yi-ti-jiao-ren-zheng-shen-qing-qing-nai-xin-deng-dai')
          )
          this.$router.back()
        }
      })
    },
    save() {
      this.$store.state.avatar = this.avatar
      this.back()
    },
    changeHeader(i) {
      this.avatar = i
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.lastIndexOf('.') + 1).trim().toLowerCase();
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'));
        return false;
      }
      return true
    },
    uploadSuccess(file, key) {
      this.form[key] = file.data.FileName
      this.$forceUpdate()
    },
    submitAuth() {
      if (!this.form.country) {
        this.$toast(this.$t('qing-shu-ru-guo-ji-0'))
        return
      }
      if (!this.form.realName) {
        this.$toast(this.$t('qing-shu-ru-zhen-shi-xing-ming-4'))
        return
      }
      if (!this.form.cardNo) {
        this.$toast(this.$t('qing-shu-ru-zheng-jian-hu-zhao-hao-ma-4'))
        return
      }
      if (!this.form.cardFront) {
        this.$toast(this.$t('qing-shang-chuan-zheng-jian-fan-mian'))
        return
      }
      if (!this.form.cardBack) {
        this.$toast(this.$t('qing-shang-chuan-zheng-jian-zheng-mian'))
        return
      }
      shopAuth({
        RealName: this.form.realName,
        IdCard: this.form.cardNo,
        IdCardFront: this.form.cardFront,
        IdCardBack: this.form.cardBack,
        ShopCountry: this.form.country
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg)
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>